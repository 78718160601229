.formHolder {
  .form-group {
    margin-bottom: 24px;
  }

  label,
  .labelLike {
    font-size: 13px;
    color: rgba(68, 68, 68, 0.56);
    font-weight: 600;
  }

  .floatLabel {
    position: relative;

    label,
    .labelLike {
      background: #fff;
      display: block;
      position: absolute;
      transition: 0.2s;
      padding: 0 4px;
      top: 0;
      left: 8px;
      transform: translateY(-10px);
      font-size: 13px;
    }
  }

  .form-control {
    display: block;
    border-radius: 6px;
    border: solid 1px rgba(68, 68, 68, 0.16);
    background-color: #ffffff;
    color: rgba(68, 68, 68, 0.88);
    font-size: 13px;
    font-weight: 600;
    padding: 12px;
    height: 48px;
    width: 100%;
    outline: none;
    box-shadow: none;
  }

  .errorMsg {
    color: #e72222;
    font-size: 13px;
    display: none;
    margin-left: 12px;
    margin-top: 4px;
  }

  .hasError {
    label,
    .labelLike {
      color: #e72222;
    }

    .form-control {
      border-color: #e72222;
    }

    .errorMsg {
      display: block;
    }
  }
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.formControlTextarea {
  .MuiOutlinedInput-inputMultiline {
    height: auto;
  }

  .MuiOutlinedInput-multiline {
    padding: 11.5px 15px;
  }

  &.fixedHeight {
    .MuiOutlinedInput-inputMultiline {
      height: 76px;
    }
  }
}

.selectWithDelete {

}

.checkboxListHolder {
  position: relative;

  ::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(68, 68, 68, 0.16);
    background-position: center;
    background-repeat: no-repeat;
    -webkit-border-radius: 3.5px;
    border-radius: 3.5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-button {
    background-image: none;
    background-repeat: no-repeat;
    width: 0;
    height: 0;
  }

  & > .labelLike {
    padding: 0 4px;
    top: 0;
    transform: translateY(-10px);
    display: block;
    position: absolute;
    left: 8px;
    background: #fff;
    font-size: 13px;
    z-index: 3;

    &.licence {
      left: auto;
      right: 265px;
    }

    &.dateStart {
      left: auto;
      right: 152px;
    }

    &.dateStop {
      left: auto;
      right: 8px;
    }
  }
  & > .updatedAtLabel {
    padding: 0 4px;
    top: 0;
    transform: translateY(-10px);
    display: block;
    position: absolute;
    right: 42px;
    // background: #fff;
    font-size: 13px;
    z-index: 3;
  }
}
.customDateRangeBtn {
  color: rgba(68, 68, 68, 0.88) !important;
  font-size: 13px !important;
  text-transform: none !important;
  width: 100% !important;
  padding-left: 8px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  justify-content: start !important;
  font-weight: 400 !important;
  border-radius: 0 !important;
}

.popoverCalendarHolder {
  padding: 0 32px 32px;
}

.liveBadge {
  background-color: #f4233e;
  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  padding: 0 8px;
  padding-left: 18px;
  border-radius: 12px;
  position: relative;
  height: 18px;
  line-height: 18px;
  display: inline-flex;

  &:before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 8px;
    margin-top: -3px;
    animation-duration: 1s;
    animation-name: live;
    animation-iteration-count: infinite;
  }
}

.themeItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 13px;

  &:before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 8px;
  }

  &:after {
    content: "";
    display: block;
    background-image: url("#{$imgPath}/bold-round-check-18px.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 2px;
    margin-top: -7px;
    transform: scale(0);
    transition: transform 0.2s;
  }

  &.active {
    &:after {
      transform: scale(1);
    }
  }

  &.blue {
    &:before {
      background-color: #006ae7;
    }
  }

  &.prime {
    &:before {
      background-color: #0094c2;
    }
  }

  &.dark {
    &:before {
      background-color: #252525;
    }
  }
}

.radioLabel,
.MuiFormLabel-root.radioLabel {
  font-size: 13px;
  color: rgba(68, 68, 68, 0.88);
  margin-bottom: 5px;
  font-weight: 400;

  &.Mui-focused {
    color: rgba(68, 68, 68, 0.88);
  }
}

.switchHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .radioLabel {
    margin-bottom: 0;
  }
}
.checkboxListContainer.error {
  border-color: red;
}

.checkboxListContainer {
  border-radius: 6px;
  border: solid 1px rgba(68, 68, 68, 0.16);
  padding: 8px 8px 0 8px;
  height: 220px;
  min-height: 150px;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;

  .MuiFormControlLabel-label {
    width: 100%;
    font-size: 13px;
  }

  // @media (max-width: $screen-med) {
  //   .MuiFormControlLabel-label {
  //     width: 100%;
  //     font-size: 10px;
  //   }
  // }

  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-left: 0;
  }

  .MuiIconButton-root {
    padding: 4px;

    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
  }

  label {
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-left: 0;

    &:hover {
      background-color: darken(#fff, 3%);
    }
  }

  .channel-list-item,
  .listItemWithRemove {
    &:hover {
      background-color: darken(#fff, 3%);
       .MuiButton-root {
        background-color: darken(#fff, 10%);
      }
    }

    label {
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.optionsSwitch {
  position: relative;
  border-radius: 20px;
  border: solid 1px rgba(68, 68, 68, 0.24);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  height: 36px;
  flex-shrink: 0;

  &:before {
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    border-radius: 20px;
    background: $primaryColor;
    position: absolute;
    top: 0;
    left: 0;
    transition: left 0.3s;
  }

  .option {
    width: 50%;
    font-size: 13px;
    font-weight: 600;
    color: rgba(68, 68, 68, 0.56);
    padding: 8px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &:first-child {
      color: #fff;
    }
  }

  &.in {
    &:before {
      left: 50%;
    }

    .option {
      color: #fff;

      &:first-child {
        color: rgba(68, 68, 68, 0.56);
      }
    }
  }
}

.setHeadingInput {
  .MuiInputBase-input {
    font-weight: 600;
    font-size: 18px;
    color: rgba(68, 68, 68, 0.88);
    padding: 6px 0 6px;
  }

  .MuiInput-underline {
    &:before {
      border-color: rgba(68, 68, 68, 0.16);
    }

    &:after {
      border-bottom-width: 1px;
      border-color: rgba(68, 68, 68, 0.16);
    }

    &:hover {
      &:not(.Mui-disabled) {
        &:before {
          border-bottom-width: 1px;
          border-color: rgba(68, 68, 68, 0.16);
        }
      }
    }
  }
}

.unit-list-item {
  .title {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(68, 68, 68, 0.88);
    text-transform: uppercase;
    cursor: pointer;
    padding: 4px 0;

    .arrow {
      margin-right: 10px;
      transform: rotate(0deg);
      transition: transform 0.2s;
      display: block;
      width: 18px;
      height: 18px;
      background-image: url("#{$imgPath}/round-keyboard_arrow_down-24px.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      flex-basis: 18px;

      &.arrow-open {
        transform: rotate(-90deg);
      }
    }
  }

  .channel-list {
    padding-left: 10px;
  }

  .channel-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiFormControlLabel-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .MuiFormControlLabel-root {
      overflow: hidden;
      margin-right: 0;
    }

    .MuiCheckbox-root {
      padding: 4px;
    }

    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }

    .MuiButton-text {
      padding: 4px 16px;
    }
  }
}

.listItemWithRemove {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .MuiIconButton-root {
    padding: 4px;

    .removeIcon {
      width: 20px;
      height: 20px;
      flex-basis: 20px;
    }
  }
}

.formContentBlock {
  .MuiFormControlLabel-root {
    margin-left: -5px;
  }

  .langRadioBtn {
    input[type="radio"] {
      width: 20px;
      height: 20px;
    }

    .MuiFormControlLabel-root {
      padding: 5px 0;

      .MuiTypography-root {
        font-size: 13px;
      }
    }
  }
}
.radioButtonGroup {
  margin-top: 10px;
  padding: 8px 24px;
  display: flex;
  gap: 15px;
}

.radioButtonLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(34, 34, 34, 0.88) !important;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1;
}

.radioButtonLabel input[type="radio"] {
  margin: 0;
  cursor: pointer;
  accent-color: rgb(140, 140, 140); 
}

.slide {
  opacity: 0;
  // transform: translateY(-20px);
  transition: opacity 0.1s ease-in-out,  height 0.3s ease-in-out;
}


.slide.show {
  margin-top: 0px;
  height: 60px;
  opacity: 1;
  margin-bottom: -10px;
  // transform: translateY(0);
}
.slide.hide {
  pointer-events: none;
  height: 0;
  opacity: 0;
  // transform: translateY(-20px);
}
.slide.showUnassigned {
  margin-top: -8px;
  height: 50px;
  opacity: 1;
  margin-bottom: 5px;
}
.slide.showActivity {
  margin-top: 10px;
  height: 100px;
  opacity: 1;
  // transform: translateY(0);
}



.formNoteLabel {
  margin-top: 15px;
  margin-bottom: 20px;
}

.jobLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .infoPart {
    // flex-basis: 288px;
    width: 300px;
    display: flex;
  }

  .labelName {
    width: 300px;
  }

  .date {
    width: 100%;
    margin-left: 24px;
    text-align: right;
    white-space: nowrap;
  }

  @media (max-width: $screen-med) {
    .labelName {
      width: 140px;
    }
  }
}

.selectWithSwitchHolder {
  display: flex;
  margin-top: 24px;

  .selectHolder {
    flex-grow: 1;

    .MuiOutlinedInput-notchedOutline {
      border-radius: 6px 0 0 6px;
    }
  }

  .switchHolder {
    border: 1px solid rgba(68, 68, 68, 0.16);
    border-left: none;
    padding: 0 0 0 12px;
    border-radius: 0 6px 6px 0;

    &.autoScalingSwitcher {
      .MuiTypography-body1 {
        font-size: 13px;
      }
    }
  }
}

.calendarHolder {
  font-size: 13px;

  &.noPadding {
    & > div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  & > div {
    width: 100%;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;

    & > div {
      & > div:nth-child(2) {
        & > * {
          color: rgba(68, 68, 68, 0.88);
          cursor: pointer;

          button {
            cursor: pointer;
            background-image: url("#{$imgPath}/round-keyboard_arrow_left-24px.svg");
            background-size: 24px;
            border-radius: 50%;
            width: 48px;
            height: 48px;
            background-position: center;
            background-repeat: no-repeat;
            transition: 0.1s;

            &:hover {
              background-color: rgba(0, 0, 0, 0.04);
            }

            svg {
              display: none;
            }
          }

          &:first-child {
            right: 60px;
            left: unset;
            top: -11px;

            button {
            }
          }

          &:last-child {
            top: -11px;

            button {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  [data-testid="MonthGrid"] {
    & > * {
      & > div:first-child {
        justify-content: flex-start;
        padding-left: 8px;
      }
    }

    display: flex;
    justify-content: space-between;
  }

  [data-testid="MonthGrid"] {
    display: flex;
    justify-content: space-between;

    & > * {
      &:nth-child(2) {
        //margin-left: 32px;
      }
    }
  }

  [data-testid="DatepickerNavButton"] {
    border: none;
  }

  [data-testid="Day"] {
    box-shadow: none;
    color: rgba(68, 68, 68, 0.88);
    font-size: 13px;
  }

  [data-testid="MonthLabel"] {
    color: rgba(68, 68, 68, 0.88);
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
  }

  [data-testid="DayLabel"] {
    color: rgba(68, 68, 68, 0.56);
    font-size: 13px;
    font-weight: 300;
  }
}

.timeHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .inputHolder {
    width: calc(50% - 16px);
  }
}

.axisSettings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .btnHolder {
    margin-top: auto;
  }
}

.modalFilters {
  .labelName {
    font-size: 13px;
  }
}

.modalFieldLabelSettings {
  .MuiOutlinedInput-notchedOutline {
    border: solid 1px;
    border-color: rgb(43 43 43 / 34%);
    border-radius: 6px;
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgb(43 43 43 / 54%) !important;
  }
  .Mui-error  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgb(247, 6, 6) !important;
  }
  .modalFieldLabel {
    color: rgba(34, 34, 34, 0.88) !important;
  }
  .MuiFormLabel-root {
    color: rgba(68, 68, 68, 0.56); 
  }
  .MuiOutlinedInput-input {
    color: rgba(68, 68, 68, 0.88);
  }
  .MuiSelect-outlined {
    color: rgba(68, 68, 68, 0.88);
  }
}


.doubleInput {
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
    flex-grow: 1;
  }
  .MuiOutlinedInput-input {
    //height: auto;
    //padding: 3px 8px;
    text-align: left;
    &.Mui-disabled {
      color: rgba(68, 68, 68, 0.28);
    }

  }

  // .MuiOutlinedInput-root:not(.Mui-disabled):hover
  // .MuiOutlinedInput-notchedOutline {
  //   border-color: rgba(68, 68, 68, 0.16) !important;
  // }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgb(43 43 43 / 34%) !important;
    border-width: 1px;
  }
  .MuiOutlinedInput-root:hover.MuiFocused .MuiOutlinedInput-notchedOutline  {
    border-color: rgb(43 43 43 / 54%) !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-right-width: 0 !important;
    border-radius: 6px 0 0 6px;
  }

  .last {
    .MuiOutlinedInput-notchedOutline {
      border-right-width: 1px !important;
      border-left-width: 0 !important;
      border-radius: 0 6px 6px 0;
    }

    &:before {
      content: "";
      display: block;
      width: 1px;
      height: 25px;
      position: absolute;
      left: 0;
      top: 50%;
      background: rgba(68, 68, 68, 0.16);
      transform: translateY(-50%) rotate(15deg);
    }
  }

  &.hasSelect {
    .last {
      &:before {
        display: none;
      }
    }

    .first {
      .MuiOutlinedInput-notchedOutline {
        border-right-width: 1px !important;
        border-radius: 6px 0 0 6px;
      }
    }

    .MuiSelect-root {
      text-align: left;
    }

    .MuiSelect-iconOutlined {
      right: 4px;
    }
  }
}

.checkedCheckboxLabel {
  color: rgba(68, 68, 68, 0.88);
  padding: 0;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.сheckboxLabel {
  color: rgba(68, 68, 68, 0.88) !important;
  padding: 0;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.completedCheckboxLabel {
  color: rgb(43 43 43 / 34%) !important;
  padding: 0;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.activityForm {
    .MuiFormLabel-root {
        color: rgb(43 43 43 / 54%);
      }
    
      .MuiOutlinedInput-notchedOutline {
        border: solid 1px;
        border-color: rgb(43 43 43 / 34%);
        border-radius: 6px;
      }
    
      .MuiFormLabel-root.Mui-disabled {
        color: rgba(68, 68, 68, 0.56) !important;
      }
    
      .MuiButton-root.Mui-disabled {
        color: rgba(68, 68, 68, 0.28) !important;
      }
    
      .MuiButton-text {
        color: rgba(68, 68, 68, 0.88);
        padding: 0;
        font-size: 13px;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
      }
    
      .formControlDatePicker:disabled {
        border: 1px solid rgba(68, 68, 68, 0.16);
        color: rgba(68, 68, 68, 0.56);
    
        &:hover {
          border: 1px solid rgba(68, 68, 68, 0.16);
        }
      }
    
      .formControlDatePicker {
        border-color: rgb(43 43 43 / 34%);
    
        &:hover {
          border-color: rgb(43 43 43 / 54%);
        }
      }
    .MuiOutlinedInput-root.Mui-disabled > .MuiOutlinedInput-notchedOutline {
      border: 1px solid rgba(68, 68, 68, 0.16) !important;
    }
    .datePickerWithLabel .labelLike {
      color: rgba(68, 68, 68, 0.88);
    }
    .disabled {
      color: rgba(68, 68, 68, 0.56) !important;
    }
    .MuiIconButton-root.Mui-disabled {
      color: rgba(68, 68, 68, 0.28) !important;
    }
    .activityDescriptionLabel {
      .MuiFormLabel-root {
        color: rgba(68, 68, 68, 0.88);
    }
    }

    .MuiFormControl-root {
      width: 100%;
      flex-grow: 1;
    }
    .uploadBtnLabel {
      color: rgba(68, 68, 68, 0.88) !important;
      font-size: 13px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
    }

    .MuiOutlinedInput-input {
      //height: auto;
      //padding: 3px 8px;
      text-align: left;
      padding-top: 9px;
      &.Mui-disabled {
        color: rgba(68, 68, 68, 0.56);
      }

    }

    // .MuiOutlinedInput-root:not(.Mui-disabled):hover
    // .MuiOutlinedInput-notchedOutline {
    //   border-color: rgba(68, 68, 68, 0.16) !important;
    // }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgb(43 43 43 / 34%) !important;
      border-width: 1px;
    }

    .activityFormGridSize {
      display: grid; 
      grid-template-columns: 18% 24% 25% 26%; 
      gap: 15px;
      @media (max-width: 700px) {
        grid-template-columns: 102px 161px 160px 151px;
        gap: 5px;
        margin-left: 106px; 
        .activityFormLeftSide {
          display: flex;
          position: absolute;
          left: 16px;
          background-color: white;
          z-index: 50;
          width: 103px;
          .MuiCheckbox-root {
            color:  rgb(43 43 43 / 34%) !important;
          }
          
        }
      }
    }

    .onDemandCheckbox {
      display: grid; 
      grid-template-columns: 18% 24% 25% 26%; 
      gap: 15px;
      
      @media (max-width: 700px) {
        grid-template-columns: 102px 161px 160px 151px;
        gap: 5px;
        margin-left: 106px;
        margin-bottom: 52px; 
        .onDemandCheckboxLeftSide {
          display: flex;
          position: absolute;
          left: 16px;
          background-color: white;
          z-index: 50;
          width: 108px;
        }
      }
    }

    @media (max-width: 630px) {
      .overflowContent {
        overflow-x: auto;
      }
      .react-datepicker-popper {
        position: fixed !important;
        top: 300px !important;
        left: 110px !important;
        z-index: 100;
      }
      .react-datepicker__portal {
        position: fixed;
          width: 0;
          height: 0;
          background-color: transparent;
          left: 219px;
          top: 562px;
          justify-content: center;
          align-items: center;
          display: flex;
          z-index: 2147483647;
      }
    }
    

    &.hasSelect {
      .last {
        &:before {
          display: none;
        }
      }

      .MuiSelect-root {
        text-align: left;
      }

      .MuiSelect-iconOutlined {
        right: 4px;
      }
    }

  .doubleInput {
    display: flex;
    align-items: center;

    .MuiFormControl-root {
      width: 100%;
      flex-grow: 1;
    }

    .MuiOutlinedInput-input {
      text-align: left;
      &.Mui-disabled {
        color: rgba(68, 68, 68, 0.56);
      }

    }

    // .MuiOutlinedInput-root:not(.Mui-disabled):hover
    // .MuiOutlinedInput-notchedOutline {
    //   border-color: rgba(68, 68, 68, 0.16) !important;
    // }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgb(43 43 43 / 34%) !important;
      border-width: 1px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-right-width: 0 !important;
      border-radius: 6px 0 0 6px;
    }

    .last {
      .MuiOutlinedInput-notchedOutline {
        border-right-width: 1px !important;
        border-left-width: 0 !important;
        border-radius: 0 6px 6px 0;
      }

      &:before {
        content: "";
        display: block;
        width: 1px;
        height: 25px;
        position: absolute;
        left: 0;
        top: 50%;
        background: rgba(68, 68, 68, 0.16);
        transform: translateY(-50%) rotate(15deg);
      }
    }

    &.hasSelect {
      .last {
        &:before {
          display: none;
        }
      }

      .MuiSelect-root {
        text-align: left;
      }

      .MuiSelect-iconOutlined {
        right: 4px;
      }
    }
  }
}


.axisSettingsTableLike {
  font-size: 13px;

  .range {
    margin-right: 25px;
    @media (max-width: $screen-med) {
      margin-right: 0px;
    }
  }
  .format {
    margin-right: 18px;
    @media (max-width: $screen-med) {
      margin-right: 1px;
    }
  }
  .mode {
    margin-right: 27px;
    @media (max-width: $screen-med) {
      margin-right: 1px;
    }
  }

  .labelName {
    font-size: 13px;
  }

  .tr {
    display: flex;
    padding: 0 15px;
    align-items: center;

    &:first-child {
      .td {
        padding-top: 10px;
      }
    }

    &:last-child {
      .td {
        padding-bottom: 10px;
      }
    }

    &.hasBorderBottom {
      border-bottom: 1px solid $borderColor;

      .td {
        padding: 5px 5px 10px;
      }
    }

    &.hasBorderBottom + .tr {
      .td {
        padding-top: 10px;
      }
    }

    &.innerLikeRow {
      padding-left: 40px;
    }

    &.thead {
      .td {
        font-weight: 600;
      }
    }
  }

  .td {
    // flex-basis: 170px;
    text-align: center;
    padding: 5px;
    
  }

  .main {
    // flex-grow: 1;
    text-align: left;
    @media (max-width: $screen-med) {
      min-width: 100px;
    }
  }

  .thead {
    border-bottom: 1px solid $borderColor;
    margin-bottom: 5px;

    .td {
      padding: 5px;
    }
  }

  .doubleInput {
    display: flex;
    align-items: center;

    .MuiFormControl-root {
      width: 100%;
      flex-grow: 1;
    }

    .MuiOutlinedInput-input {
      height: auto;
      padding: 3px 8px;
      text-align: center;
    }

    .MuiOutlinedInput-root:not(.Mui-disabled):hover
    .MuiOutlinedInput-notchedOutline {
      border-color: rgb(43 43 43 / 54%) !important;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgb(43 43 43 / 34%) !important;
      border-width: 1px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-right-width: 0 !important;
      border-radius: 6px 0 0 6px;
    }

    .last {
      .MuiOutlinedInput-notchedOutline {
        border-right-width: 1px !important;
        border-left-width: 0 !important;
        border-radius: 0 6px 6px 0;
      }

      &:before {
        content: "";
        display: block;
        width: 1px;
        height: 25px;
        position: absolute;
        left: 0;
        top: 50%;
        background: rgba(68, 68, 68, 0.16);
        transform: translateY(-50%) rotate(15deg);
      }
    }

    &.hasSelect {
      .last {
        &:before {
          display: none;
        }
      }

      .MuiSelect-root {
        text-align: left;
      }

      .MuiSelect-iconOutlined {
        right: 4px;
      }
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.divHasError {
  text-decoration: underline;
  text-decoration-color: #f44336;
}

@keyframes live {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.box-filters {
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
  }
  //.MuiSvgIcon-root {
  //  font-size: .9em;
  //}
}

.replaceModalDatePicker {
  .MuiOutlinedInput-adornedEnd {
    padding-right: 5px !important;
  }
}

.DateTimePickerFrom {
  width: 100%;
  min-width: 200px;
  max-width: 250px;
  position: relative;
}

.DateTimePickerTo {
  width: 100%;
  min-width: 200px;
  max-width: 250px;
  position: relative;
}

.datatableDatepickerContainer {
  width: 350px;
  position: absolute;
  z-index: 2;
}

@media (min-width: $screen-med) {
  .box-filters {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: $screen-large) {
  .box-filters {
    display: flex;
  }
  .box-filters-container {
    display: flex;
  }
}

.DateTimePickerFrom {
  padding-bottom: 10px;
}

@media (min-width: $screen-med) {
  .DateTimePickerFrom {
    padding-right: 15px;
  }
}

.DateTimePickerTo {
  padding-bottom: 10px;
}

@media (min-width: $screen-med) {
  .DateTimePickerTo {
    padding-right: 15px;
  }
}

.stepSizeChange {
  padding-bottom: 10px;
}

@media (min-width: $screen-med) {
  .stepSizeChange {
    padding-right: 15px;
  }
}

@media (min-width: $screen-med) {
  .stepSizeValue {
    padding-right: 15px;
  }
}

.refreshBtn {
  position: absolute!important;
  top: 50px;
  right: 28px;
  opacity: 0.7;
}

@media (min-width: 380px) {
  .refreshBtn {
    position: unset;
    top: 3px;
    right: 78px;
  }
}

.search {
  background-color: #fff;
}


.theme-dark {
  .MuiPickersToolbar-toolbar, .MuiPickerDTTabs-tabs {
    background-color: #303030;
  }

  .MuiPickersCalendarHeader-iconButton {
    background-color: #424242;
  }

  .MuiPickersDay-day {
    color: #fff;
  }

  .MuiPickersCalendarHeader-dayLabel, .MuiPickersClockNumber-clockNumber {
    color: rgba(255, 255, 255, 0.5);
  }
}

.MuiPickersBasePicker-container {
  .MuiTypography-h4 {
    font-size: 1rem;
  }
  .MuiTypography-h3 {
    font-size: 1.2rem;
  }

  .MuiPickersCalendarHeader-dayLabel {
    width: 30px;
  }

  .MuiPickersDay-day {
    height: 30px;
    width: 30px;
  }

  .MuiTab-root {
    min-width: 100px;
  }

  .MuiPickersToolbar-toolbar {
    height: auto;
  }

  .MuiPickersBasePicker-pickerView {
    min-width: 270px;
  }
}
